import React from "react"
import "../styles/styles.scss"

import Header from "../components/header"
import Demo from "../components/demo"
import Footer from "../components/footer"

import Hero from "../clientx/hero"
import Features from "../clientx/features"

import {Helmet} from 'react-helmet'


const IndexPage = () => (
    <div>
      <Helmet>
        <html lang="en" />
        <title>Skialabs - ClientX. </title>
        <description>ClientX - de app voor de administratie.</description>
      </Helmet>
      <Header />
      <Hero />
      <Features />
      <Demo />
      <Footer />
    </div>
)

export default IndexPage
