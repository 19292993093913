import React from "react"
import { FaRoute, FaSortAmountUp, FaAddressCard} from "react-icons/fa"

const Features = () => {
    return(
        <div className="features-applications">
        <div className="container">
            <div className="row-feat header">
                <div className="column-feat header">
                <div className="main-text"> Functionaliteiten </div>
                <div className="sub-text">  Kies uit onze geavanceerde modules om de applicatie volledig te personaliseren naar 
                jouw proces.  </div>
                </div>
            </div>
            <div className="row-feat">
                <div className="column-feat">
                    <div className="title">  Voorspel.</div>
                    <div> <FaRoute size={50} color={"#7FACD6"}/></div>
                    <div>Machine learning engine voor voorspellen van verwacht afval. </div>
                    <div>Historische analyse gereden routes, meldingen en ingezameld afval. </div>
                    <div>Snelle data-gedreven inzichten voor het efficienter inzetten van de vloot.  </div>
                </div>
                <div className="column-feat">
                    <div className="title">  Optimaliseer.</div>
                    <div> <FaSortAmountUp size={50} color={"#7FACD6"}/> </div>
                    <div>Optimaal voor alle voertuigen, afval types, en container types.   </div>
                    <div>Diverse optimalisatie opties: clusters, minimale rijtijd, minimaal gebruikte voertuigen.  </div>
                    <div>Pas routes gedurende de dag dynamisch aan. </div>
                </div>
                <div className="column-feat">
                    <div className="title">Beheer.</div>
                    <div> <FaAddressCard size={50} color={"#7FACD6"}/> </div>
                    <div>Process monitor: volg de voortgang gedurende de dag.   </div>
                    <div>Verwerk binnengekomen meldingen van klanten of chauffeurs automatisch of handmatig.</div>
                    <div>Volledig klantbeheer en facturatie. </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Features