import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Hero = () => {
    const data = useStaticQuery(graphql`
    query {
      heroimage: file(relativePath: { eq: "clientx-all.png" }) {
        childImageSharp {
          fluid(maxWidth: 1540) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `)
    return (
        <div className="hero-applications">
            <div className="container">
            <div className='column'>
                <div>
                <div className="main-text"> Client <span>X.</span></div>
                <div className="sub-text">  Alles voor de administratie in één applicatie.   </div>
                </div>
                <div className = 'sideimage'> 
                  <Img className = 'graphic' fluid={data.heroimage.childImageSharp.fluid} />
                </div>
                <div className='child'>
                    <h3> Data-gedreven efficiëntie. </h3>
                    <p>
                    Onze algoritmes gebruiken alle relevante informatie over de stadsinfrastructuur, rij-beperkingen, chauffeur 
                    voorkeuren en drukte. Ze werken voor ieder voertuig: traditionele vuilniswagens, elektrische voertuigen, 
                    fietsen en meer. Gebruik onze machine learning engine voor het voorspellen van de verwachtte aantallen en type afval. 
                    </p>
                </div>
                <div className='child right'>
                    <h3> Snelle inzichten. </h3>
                    <p>
                    Realtime en historische informatie over alle processen. Volg de chauffeurs en analyseer de voortgang. 
                    Zie real-time alle binnengekomen meldingen van klanten en chauffeurs en los ze direct op.
                    </p>
                </div>
                <div className='child'>
                    <h3> Een volledig klantmanagement systeem.  </h3>
                    <p>
                    Beheer klant-contracten, productlijsten en aan-te-vragen diensten. Een volledig geïntegreerd 
                    facturatie-systeem verwerkt automatisch alle klant-aanvragen en verstuurd facturen.
                    </p>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Hero

